import React from 'react';
import { Chart } from 'react-google-charts';

export function BarPie({ data }) {
    const options = {
        title: 'Qtde Plantas/ha',
        pieHole: 0.5,
        is3D: false,
        colors: ['#015641', '#2980B9', '#34234D']
    };
    // console.log('pie', data);
    return <Chart chartType="PieChart" width="100%" height="300px" data={data} options={options} />;
}
