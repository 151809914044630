export function createColumnsPerMonth(data) {
    const monthNames = [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro'
    ];

    const rowsByMonth = data.reduce((acc, row) => {
        console.log(row);
        const year = row.ano;
        const month = row.mes;
        const id = row.id;
        const hh = parseFloat(row.hh || 0);
        const hm = parseFloat(row.hm || 0);

        if (!acc[year]) {
            acc[year] = {};
        }

        const monthIndex = monthNames.indexOf(month.toLowerCase());

        if (!acc[year][monthIndex]) {
            acc[year][monthIndex] = {
                id: id,
                mes: month,
                ano: year,
                hhTotal: hh,
                hmTotal: hm,
                rows: [row]
            };
        } else {
            acc[year][monthIndex].hhTotal += hh;
            acc[year][monthIndex].hmTotal += hm;
            acc[year][monthIndex].rows.push(row);
        }

        return acc;
    }, {});

    const rows = Object.keys(rowsByMonth)
        .sort()
        .flatMap((year) =>
            Object.keys(rowsByMonth[year])
                .sort((a, b) => a - b)
                .map((monthIndex) => ({
                    ...rowsByMonth[year][monthIndex],
                    hhTotal: rowsByMonth[year][monthIndex].hhTotal.toFixed(2),
                    hmTotal: rowsByMonth[year][monthIndex].hmTotal.toFixed(2)
                }))
        );

    rows.push({
        id: Math.random() * (100000 - 90000) + 90000,
        ano: 'Total',
        hhTotal: Object.values(rowsByMonth)
            .reduce((acc, year) => acc + Object.values(year).reduce((acc, month) => acc + month.hhTotal, 0), 0)
            .toFixed(2),
        hmTotal: Object.values(rowsByMonth)
            .reduce((acc, year) => acc + Object.values(year).reduce((acc, month) => acc + month.hmTotal, 0), 0)
            .toFixed(2)
    });

    const columns = [
        { field: 'id', hide: true },
        { field: 'ano', headerName: 'Ano', width: 220, sortable: false, hide: false },
        { field: 'mes', headerName: 'Mês', width: 220, sortable: false, hide: false },
        { field: 'hhTotal', headerName: 'HH Total', width: 120, sortable: false, hide: false },
        { field: 'hmTotal', headerName: 'HM Total', width: 390, sortable: false, hide: false }
    ];

    return { rows, columns };
}

export function createColumnsPerGroupService(data) {
    const groupedData = data.reduce((acc, curr) => {
        const key = `${curr.grupo_servico}`;
        if (!acc[key]) {
            acc[key] = {
                id: key,
                grupo_servico: curr.grupo_servico,
                data: []
            };
        }
        acc[key].data.push(curr);
        return acc;
    }, {});

    const rows = Object.values(groupedData).map((group) => {
        const { grupo_servico, data } = group;
        const uniqueAreas = new Set(data.map((d) => d.area));
        const areaSum = Array.from(uniqueAreas).reduce((total, area) => total + parseFloat(area), 0);
        const hhSum = data.reduce((total, curr) => total + parseFloat(curr.hh), 0);
        const hmSum = data.reduce((total, curr) => total + parseFloat(curr.hm), 0);
        const hhHa = areaSum > 0 ? (hhSum / areaSum).toFixed(2) : 0;
        const hmHa = areaSum > 0 ? (hmSum / areaSum).toFixed(2) : 0;

        return {
            id: grupo_servico,
            grupo_servico,
            area: areaSum.toFixed(2),
            hhHa,
            hh: hhSum.toFixed(2),
            hmHa,
            hm: hmSum.toFixed(2),
            data
        };
    });

    const columns = [
        { field: 'id', hide: true },
        { field: 'grupo_servico', headerName: 'Grupo serviço', width: 220, sortable: false, hide: false },
        { field: 'area', headerName: 'HA', width: 100, sortable: false, hide: false },
        { field: 'hhHa', headerName: 'HH/HA', width: 100, sortable: false, hide: false },
        { field: 'hh', headerName: 'HH Total', width: 100, sortable: false, hide: false },
        { field: 'hmHa', headerName: 'HM/HA', width: 100, sortable: false, hide: false },
        { field: 'hm', headerName: 'HM Total', width: 80, sortable: false, hide: false }
    ];

    return { rows, columns };
}

export function createColumnsPerService(data) {
    const columns = [
        { field: 'id', hide: true },
        { field: 'servico', headerName: 'Serviço', width: 240, sortable: false, hide: false },
        { field: 'local', headerName: 'Local', width: 180, sortable: false, hide: false },
        { field: 'hhHa', headerName: 'HH/HA', width: 100, sortable: false, hide: false },
        { field: 'hmHa', headerName: 'HM/HA', width: 100, sortable: false, hide: false }
    ];

    const groupedData = data.reduce((acc, curr) => {
        const key = curr.servico + '|' + curr.local;
        if (!acc[key]) {
            acc[key] = {
                servico: curr.servico,
                local: curr.local,
                hh: 0,
                hm: 0,
                area: 0
            };
        }
        acc[key].hh += parseFloat(curr.hh);
        acc[key].hm += parseFloat(curr.hm);
        acc[key].area = parseFloat(curr.area);
        return acc;
    }, {});

    const updatedData = Object.values(groupedData).map((obj) => {
        const hhHa = obj.hh / obj.area;
        const hmHa = obj.hm / obj.area;
        return {
            id: obj.servico + '|' + obj.local,
            servico: obj.servico,
            local: obj.local,
            hhHa: isNaN(hhHa) ? '-' : hhHa.toFixed(1),
            hmHa: isNaN(hmHa) ? '-' : hmHa.toFixed(1)
        };
    });

    let tempObj = { servico: '' };
    let newId = 1;
    let totalArea = 0;
    let localObj = {};

    for (let i = 0; i < updatedData.length; i++) {
        let area = parseFloat(data[i].area);
        if (!localObj[data[i].local]) {
            totalArea += area;
            localObj[data[i].local] = true;
        }
        updatedData[i].servicoValue = updatedData[i].servico;
        if (tempObj.servico === updatedData[i].servico) {
            updatedData[i].servico = null;
            updatedData[i].id = newId++;
        } else {
            tempObj = updatedData[i];
            tempObj.servico = tempObj.servico;
            tempObj.id = newId++;
        }
    }

    return { rows: updatedData, columns, totalArea };
}

export function createFilter(data, location = []) {
    const allFarmObj = {};
    const allDtaObj = {};
    const allActivityObj = {};
    const allStatusObj = {};
    const allGroupServiceObj = {};
    const allServiceObj = {};
    const allLocalObj = {};

    for (let i = 0; i < data.length; i++) {
        const farm = data[i].fazenda;
        if (!allFarmObj[farm]) {
            allFarmObj[farm] = true;
        }

        const atividade = data[i].atividade;
        if (!allActivityObj[atividade]) {
            allActivityObj[atividade] = true;
        }

        const grupoServico = data[i].grupo_servico;
        if (!allGroupServiceObj[grupoServico]) {
            allGroupServiceObj[grupoServico] = true;
        }

        const dta = data[i].mes;
        if (!allDtaObj[dta]) {
            allDtaObj[dta] = true;
        }

        const servicoOrigin = data[i].servicoOrigin;
        if (!allServiceObj[servicoOrigin]) {
            allServiceObj[servicoOrigin] = true;
        }

        const status = data[i].status;
        if (!allStatusObj[status]) {
            allStatusObj[status] = true;
        }

        const local = data[i].local;
        if (!allLocalObj[local]) {
            allLocalObj[local] = true;
        }
    }

    const allServico = Object.keys(allServiceObj);
    const allFarm = Object.keys(allFarmObj);
    const allActivity = Object.keys(allActivityObj);
    const allGroupService = Object.keys(allGroupServiceObj);
    const allStatus = Object.keys(allStatusObj);
    const allLocal = location.length > 0 ? location : Object.keys(allLocalObj);
    const allDta = Object.keys(allDtaObj);

    const filter = { allFarm, allActivity, allStatus, allGroupService, allLocal, allServico, allDta };

    return filter;
}
