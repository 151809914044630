export const initialState = {
    date: null,
    classes: null,
    inputs: null,
    locais: null,
    loading: false,
    farms: null,
    status: null,
    groupServices: null,
    dta: null,
    activitys: null,
    stateDrawer: false,
    services: null
};

const adubacaoReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_FILTER_DATE':
            return {
                ...state,
                date: actions.payload
            };
        case 'SET_FILTER_CLASS':
            return {
                ...state,
                classes: actions.payload
            };
        case 'SET_FILTER_LOCAL':
            return {
                ...state,
                locais: actions.payload
            };
        case 'SET_FILTER_LOADING':
            return {
                ...state,
                loading: actions.payload
            };
        case 'SET_FILTER_INPUT':
            return {
                ...state,
                inputs: actions.payload
            };
        case 'SET_FILTER_FARM':
            return {
                ...state,
                farms: actions.payload
            };
        case 'SET_FILTER_ACTIVITY':
            return {
                ...state,
                activitys: actions.payload
            };
        case 'SET_FILTER_STATUS':
            return {
                ...state,
                status: actions.payload
            };
        case 'SET_FILTER_GROUP_SERVICE':
            return {
                ...state,
                groupServices: actions.payload
            };
        case 'SET_FILTER_SERVICE':
            return {
                ...state,
                services: actions.payload
            };
        case 'SET_FILTER_DTA':
            return {
                ...state,
                dta: actions.payload
            };
        case 'SET_STATE_DRAWER':
            return {
                ...state,
                stateDrawer: actions.payload
            };
        default:
            return state;
    }
};

export default adubacaoReducer;
