import { Grid } from '@material-ui/core';
import TableGridFertilizing from '../../components/Tables/TableGridFertilizing';
import Buttom from '../../components/Buttom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Container, FormContainer } from './styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useState } from 'react';
import FormDrawer from '../../components/FormDrawer';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    list: {
        width: 450
    },
    fullList: {
        width: 'auto'
    }
});

function Adubacao() {
    const css = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const filterInitialFarm = searchParams.get('fazenda');
    const filterInitialHarvest = searchParams.get('safra');

    const meses = useSelector((state) => state.adubacao.date);
    const classes = useSelector((state) => state.adubacao.classes);
    const insumos = useSelector((state) => state.adubacao.inputs);
    const farms = useSelector((state) => state.adubacao.farms);
    const status = useSelector((state) => state.adubacao.status);
    const activitys = useSelector((state) => state.adubacao.activitys);
    const groupServices = useSelector((state) => state.adubacao.groupServices);
    const loading = useSelector((state) => state.adubacao.loading);
    const locais = useSelector((state) => state.adubacao.locais);

    const unidade = ['L', 'KG', 'ML', 'G', 'HA'];

    const [filterClass, setFilterClass] = useState([]);
    const [filterInput, setFilterInput] = useState([]);
    const [filterUnit, setFilterUnit] = useState([]);
    const [filterDate, setFilterDate] = useState([]);
    const [filterFarm, setFilterFarm] = useState([]);
    const [filterActivitys, setFilterActivitys] = useState([]);
    const [filterGroupService, setFilterGroupService] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [filterLocal, setFilterLocal] = useState([]);
    const [search, setSearch] = useState(false);
    const [hideFilter, setHideFilter] = useState(false);
    const [expanded, setExpanded] = useState(true);

    const generateForm = [
        { data: farms, onChange: setFilterFarm, value: filterFarm, label: 'Fazenda', multiSelect: false },
        {
            data: locais,
            onChange: setFilterLocal,
            value: filterLocal,
            label: 'Local',
            multiSelect: true,
            labelHelper: 'Selecione uma fazenda',
            disabled: filterFarm.length <= 0 ? true : false
        },
        { data: meses, onChange: setFilterDate, value: filterDate, label: 'Mês', multiSelect: true },

        { data: status, onChange: setFilterStatus, value: filterStatus, label: 'Status', multiSelect: true },

        { data: activitys, onChange: setFilterActivitys, value: filterActivitys, label: 'Atividade', multiSelect: true },
        { data: groupServices, onChange: setFilterGroupService, value: filterGroupService, label: 'Grupo de serviço', multiSelect: true },
        { data: classes, onChange: setFilterClass, value: filterClass, label: 'Classe', multiSelect: true }
    ];

    function clearFilter() {
        setFilterClass([]);
        setFilterInput([]);
        setFilterUnit([]);
        setFilterFarm([]);
        setFilterDate([]);
        setFilterActivitys([]);
        setFilterGroupService([]);
        setFilterStatus([]);
        setFilterLocal([]);
        setSearch(!search);
    }

    function handleFilterToggle() {
        setHideFilter(!hideFilter);
        setExpanded(!expanded);
    }

    return (
        <Container container>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div>
                        <h1 style={{ color: '#015641', marginLeft: '20px' }}>Planejamento de Adubação</h1>
                    </div>
                </Grid>
                <Grid item xs={8} container justify="flex-end" alignItems="center">
                    <Link to={`/?fazenda=${filterInitialFarm}&safra=${filterInitialHarvest}`}>
                        <HomeIcon style={{ color: '#015641', fontSize: '48px', marginTop: '15px', marginRight: '10px' }} />
                    </Link>
                </Grid>
                <Grid
                    item
                    className={`filter-grid ${expanded ? 'expanded' : 'collapsed'} transition`}
                    style={{
                        width: expanded ? '25%' : '7%',
                        transition: 'all 0.3s ease'
                    }}
                >
                    {!loading && (
                        <FormContainer>
                            {hideFilter ? (
                                <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ArrowForwardIosRoundedIcon style={{ color: '#015641' }} onClick={handleFilterToggle} />
                                </Grid>
                            ) : (
                                <>
                                    <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <ArrowBackIosNewRoundedIcon style={{ color: '#015641' }} onClick={handleFilterToggle} />
                                    </Grid>
                                    <FormDrawer
                                        generateForm={generateForm}
                                        clearFilter={clearFilter}
                                        setSearch={setSearch}
                                        search={search}
                                    />
                                </>
                            )}
                        </FormContainer>
                    )}
                </Grid>
                <Grid item xs={expanded ? 9 : 11}>
                    <div style={{ height: '84vh' }}>
                        <TableGridFertilizing
                            filterClass={filterClass}
                            filterInput={filterInput}
                            filterUnit={filterUnit}
                            filterDate={filterDate}
                            filterLocal={filterLocal}
                            filterActivitys={filterActivitys}
                            filterGroupService={filterGroupService}
                            filterStatus={filterStatus}
                            filterFarm={filterFarm}
                            search={search}
                            filterInitialFarm={filterInitialFarm}
                            filterInitialHarvest={filterInitialHarvest}
                        />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Adubacao;
