import { combineReducers } from 'redux';

import adubacaoReducer from './adubacao';
import caracteristicaReducer from './caracteristica';
import agendaMacroReducer from './agendaMacro';
import filtroInicialReducer from './filtroInicial';
import allDataReducer from './allData';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    adubacao: adubacaoReducer,
    caracteristica: caracteristicaReducer,
    agendaMacro: agendaMacroReducer,
    filtroInicial: filtroInicialReducer,
    allData: allDataReducer
});

export default reducer;
