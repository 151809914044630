import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
    padding: 20px 20px 0px 20px;
`;

export const Card = styled.div`
    width: 150px;
    height: 75px;
    color: #015641;
    background-color: #e4feec6c;
    border-radius: 15px;
    border: 1px solid #015641;
    box-shadow: 0px -4px 12px -1px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px -4px 12px -1px rgba(0, 0, 0, 0.24);
    box-shadow: 0px -4px 12px -1px rgba(0, 0, 0, 0.24);
    margin-right: 10px;
    > header {
        text-align: center;
    }

    > main {
        text-align: center;
    }
`;
