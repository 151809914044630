import { DataGrid } from '@mui/x-data-grid';
import './styles.css';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Loading';
import { getAgendaMacro } from '../../../services/bi';

export default function TableGridMacroSchedule({
    filterDate,
    filterStatus,
    filterServices,
    filterActivitys,
    filterGroupService,
    filterFarm,
    filterLocal,
    search,
    filterInitialHarvest,
    filterInitialFarm
}) {
    var dataAgendaMacro = useSelector((state) => state.allData.allDataAgendaMacro);

    // Verifica se o estado é null
    if (dataAgendaMacro === null) {
        // Tenta recuperar o estado do localStorage
        const serializedStateAgendaMacro = localStorage.getItem('dataAgendaMacro');
        if (serializedStateAgendaMacro !== null) {
            // Converte o estado de volta para um objeto JavaScript
            dataAgendaMacro = JSON.parse(serializedStateAgendaMacro);
        } else {
            // Se não houver estado no localStorage, redireciona para a home
            history.push('/home');
        }
    }

    const [allData, setAllData] = useState(null);
    const [allDataOriginal, setAllDataOriginal] = useState(null);
    const [dataFilter, setDataFilter] = useState(allData);

    const [isLoading, setIsLoading] = useState(true);

    const _ = require('lodash');
    const dispatch = useDispatch();
    const Decimal = require('decimal.js');

    dispatch({ type: 'SET_FILTER_LOADING', payload: isLoading });

    function createFilter(allFarm, allActivity, allGroupService, allStatus, allLocal, allServico, allDta) {
        dispatch({ type: 'SET_FILTER_FARM', payload: allFarm });
        dispatch({ type: 'SET_FILTER_ACTIVITY', payload: allActivity });
        dispatch({ type: 'SET_FILTER_STATUS', payload: allStatus });
        dispatch({ type: 'SET_FILTER_GROUP_SERVICE', payload: allGroupService });
        dispatch({ type: 'SET_FILTER_LOCAL', payload: allLocal });
        dispatch({ type: 'SET_FILTER_SERVICE', payload: allServico });
        dispatch({ type: 'SET_FILTER_DTA', payload: allDta });
    }

    const createColumns = (data, location = []) => {
        const allFarmObj = {};
        const allDtaObj = {};
        const allActivityObj = {};
        const allStatusObj = {};
        const allGroupServiceObj = {};
        const allServiceObj = {};
        const allLocalObj = {};

        for (let i = 0; i < data.length; i++) {
            const farm = data[i].fazenda;
            if (!allFarmObj[farm]) {
                allFarmObj[farm] = true;
            }

            const atividade = data[i].atividade;
            if (!allActivityObj[atividade]) {
                allActivityObj[atividade] = true;
            }

            const grupoServico = data[i].grupo_servico;
            if (!allGroupServiceObj[grupoServico]) {
                allGroupServiceObj[grupoServico] = true;
            }

            const dta = data[i].dta;
            const dtaFormated = dta.split('-')[0] + '-' + dta.substring(5, 7);

            if (!allDtaObj[dtaFormated]) {
                allDtaObj[dtaFormated] = true;
            }

            const servico = data[i].servico;
            if (!allServiceObj[servico]) {
                allServiceObj[servico] = true;
            }

            const status = data[i].status;
            if (!allStatusObj[status]) {
                allStatusObj[status] = true;
            }

            const local = data[i].local;
            if (!allLocalObj[local]) {
                allLocalObj[local] = true;
            }
        }
        const allServico = Object.keys(allServiceObj);
        const allFarm = Object.keys(allFarmObj);
        const allActivity = Object.keys(allActivityObj);
        const allGroupService = Object.keys(allGroupServiceObj);
        const allStatus = Object.keys(allStatusObj);
        const allLocal = location.length > 0 ? location : Object.keys(allLocalObj);
        const allDta = Object.keys(allDtaObj);

        allFarm.sort((a, b) => a.localeCompare(b));
        allActivity.sort((a, b) => a.localeCompare(b));
        allGroupService.sort((a, b) => a.localeCompare(b));
        allStatus.sort((a, b) => a.localeCompare(b));
        allLocal.sort((a, b) => a.localeCompare(b));
        allDta.sort((a, b) => a.localeCompare(b));
        allServico.sort((a, b) => a.localeCompare(b));

        createFilter(allFarm, allActivity, allGroupService, allStatus, allLocal, allServico, allDta);

        const localColumns = allLocal.map((localName) => {
            return {
                field: localName,
                headerName: localName,
                width: 110,
                sortable: false,
                hide: false,
                cellClassName: (params) => {
                    if (params.row.local.includes(localName)) {
                        switch (params.row.status) {
                            case 'Concluído':
                                return 'cell-green';

                            case 'Concluído com atraso':
                                return 'cell-yellow';

                            case 'Atrasado':
                                return 'cell-red';

                            case 'Futuro':
                                return 'cell-gray';

                            case 'Solicitado':
                                return 'cell-blue';

                            case 'Em andamento':
                                return 'cell-brown';

                            default:
                                break;
                        }
                    }
                    return '';
                }
            };
        });

        const columns = [
            { field: 'id', hide: true },
            { field: 'grupo_servico', headerName: 'Grupo de serviço', width: 230, sortable: false, hide: false },
            { field: 'servico', headerName: 'Serviço', width: 250, sortable: false, hide: false },
            { field: 'fazenda', headerName: 'Fazenda', width: 180, sortable: false, hide: true },
            { field: 'local', headerName: 'Local', width: 180, sortable: false, hide: true },
            { field: 'status', headerName: 'Status', width: 200, sortable: false, hide: true },
            ...localColumns
        ];

        const rowsGruped = ([] = createRowsGrouping({ rows: data }));
        rowsGruped.sort((a, b) => {
            if (a.grupo_servico < b.grupo_servico) {
                return -1;
            }
            if (a.grupo_servico > b.grupo_servico) {
                return 1;
            }
            // se os valores de grupo_servico forem iguais, ordene por servico
            if (a.servico < b.servico) {
                return -1;
            }
            if (a.servico > b.servico) {
                return 1;
            }
            return 0; // retorna 0 se os objetos forem iguais
        });
        return { rows: rowsGruped, columns };
    };

    function createRowsGrouping(data) {
        const grouped = {};

        data.rows.forEach((row) => {
            const status = row.status;
            const fazenda = row.fazenda;
            const atividade = row.atividade;
            const grupo_servico = row.grupo_servico;
            const servico = row.servico;
            const id = row.id;
            const key = `${grupo_servico}-${servico}`;

            if (!grouped[key]) {
                grouped[key] = {
                    id,
                    status,
                    fazenda,
                    atividade,
                    grupo_servico,
                    servico,
                    local: []
                };
            }

            grouped[key].local.push(row.local);
        });

        const rows = Object.values(grouped);
        return rows;
    }

    function filterByClass(filterDate, filterStatus, filterActivitys, filterServices, filterGroupService, filterFarm, filterLocal) {
        const farmFilter = allDataOriginal.filter((obj) => obj.fazenda === filterFarm);
        const uniqueLocais = farmFilter.reduce((acc, obj) => {
            acc[obj.local] = true;
            return acc;
        }, {});
        const location = Object.keys(uniqueLocais);

        const filteredRows = _.filter(allDataOriginal, (row) => {
            const propsToCheck = ['dta', 'status', 'activitys', 'service', 'groupService', 'farm', 'local'];
            const filters = {
                status: _.isEmpty(filterStatus) || _.includes(filterStatus, row.status),
                activitys: _.isEmpty(filterActivitys) || _.includes(filterActivitys, row.atividade),
                groupService: _.isEmpty(filterGroupService) || _.includes(filterGroupService, row.grupo_servico),
                service: _.isEmpty(filterServices) || _.includes(filterServices, row.servico),
                farm: _.isEmpty(filterFarm) || _.includes(filterFarm, row.fazenda),
                dta: _.isEmpty(filterDate) || _.includes(filterDate, row.dta.split('-')[0] + '-' + row.dta.substring(5, 7)),
                local: _.isEmpty(filterLocal) || _.includes(filterLocal, row.local)
            };

            return _.every(_.pick(filters, propsToCheck));
        });

        const filteredColumn = createColumns(allDataOriginal, location);

        let visibleColumns;

        if (filterLocal.length <= 0) {
            visibleColumns = filteredColumn.columns;
        } else {
            visibleColumns = filteredColumn.columns.filter((column) => {
                return (
                    column.field === 'id' ||
                    column.field === 'grupo_servico' ||
                    column.field === 'servico' ||
                    column.field === 'status' ||
                    column.field === 'atividade' ||
                    column.field === 'fazenda' ||
                    column.field === 'local' ||
                    column.field === 'dta' ||
                    filterLocal.includes(column.field)
                );
            });
        }

        const filteredData = filteredRows.map((row) => _.pick(row, _.map(visibleColumns, 'field')));

        const rowsGruped = ([] = createRowsGrouping({ rows: filteredData }));
        rowsGruped.sort((a, b) => {
            if (a.grupo_servico < b.grupo_servico) {
                return -1;
            }
            if (a.grupo_servico > b.grupo_servico) {
                return 1;
            }
            // se os valores de grupo_servico forem iguais, ordene por servico
            if (a.servico < b.servico) {
                return -1;
            }
            if (a.servico > b.servico) {
                return 1;
            }
            return 0; // retorna 0 se os objetos forem iguais
        });
        const rowsColumns = { rows: rowsGruped, columns: visibleColumns };

        dispatch({ type: 'SET_ALL_ACTIVITIES', payload: filteredData });

        setDataFilter(rowsColumns);
    }

    function warehouseValues(value) {
        dispatch({ type: 'SET_ALL_ACTIVITIES', payload: value.data });
        dispatch({ type: 'SET_ALL_DATA_ORIGIN', payload: value.data });
        const data = createColumns(value.data);
        setDataFilter(data);
        setAllData(data);
        setAllDataOriginal(value.data);
    }

    function alterFilterFarm() {
        if (allDataOriginal !== null) {
            const filteredData = allDataOriginal?.filter((obj) => obj.fazenda === filterFarm);
            const uniqueLocais = filteredData?.reduce((acc, obj) => {
                acc[obj.local] = true;
                return acc;
            }, {});
            const newFilteredLocais = Object.keys(uniqueLocais);

            dispatch({ type: 'SET_FILTER_LOCAL', payload: newFilteredLocais });
        }
    }

    useEffect(() => {
        if (
            filterDate.length > 0 ||
            filterStatus.length > 0 ||
            filterActivitys.length > 0 ||
            filterGroupService.length > 0 ||
            filterServices.length > 0 ||
            filterFarm.length > 0 ||
            (filterLocal.length > 0 && search)
        ) {
            return filterByClass(filterDate, filterStatus, filterActivitys, filterServices, filterGroupService, filterFarm, filterLocal);
        }
        dispatch({ type: 'SET_ALL_ACTIVITIES', payload: allDataOriginal });
        setDataFilter(allData);
    }, [search]);

    useEffect(() => {
        if (dataAgendaMacro !== null) {
            dataAgendaMacro.data.forEach((obj) => {
                obj.id = uuidv4();
            });
            dispatch({ type: 'SET_ALL_ACTIVITIES', payload: dataAgendaMacro.data });
            dispatch({ type: 'SET_ALL_DATA_ORIGIN', payload: dataAgendaMacro.data });
            const data = createColumns(dataAgendaMacro.data);
            setDataFilter(data);
            setAllData(data);
            setAllDataOriginal(dataAgendaMacro.data);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        alterFilterFarm();
    }, [filterFarm]);

    useEffect(() => {
        dispatch({ type: 'SET_ALL_DATA_TABLE', payload: dataFilter });
    }, [dataFilter]);

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            className="datagrid-wrapper"
        >
            {isLoading ? (
                <Loading>Carregando</Loading>
            ) : (
                <>
                    {dataFilter !== null && (
                        <DataGrid {...dataFilter} disableColumnMenu style={{ backgroundColor: '#e4feec6c' }} showCellRightBorder={true} />
                    )}
                </>
            )}
        </div>
    );
}
