export function agrupaSimplesPercentual(data, campoAgrupa, campoSoma) {
    const total = data.reduce((acc, curr) => acc + curr[campoSoma] * 1, 0);
    const dadosAgrupados = data.reduce((acc, curr) => {
        const chave = `${curr[campoAgrupa]}`;
        acc[chave] = acc[chave] || { soma: 0, percentual: 0 };
        acc[chave].soma += curr[campoSoma] * 1;
        acc[chave].percentual = (acc[chave].soma / total) * 100;
        return acc;
    }, {});
    return dadosAgrupados;
}

// const groupBy = (array, chaves, campoSoma) => {
//     const total = array.reduce((total, item) => total + item[campoSoma], 0);
//     return array.reduce((resultado, item) => {
//       const valorChaves = chaves.map(chave => item[chave]).join('-');
//       resultado[valorChaves] = resultado[valorChaves] || { soma: 0, itens: [], percentual: 0 };
//       resultado[valorChaves].soma += item[campoSoma];
//       resultado[valorChaves].itens.push(item);
//       resultado[valorChaves].percentual = resultado[valorChaves].soma / total;
//       return resultado;
//     }, {});
//   };

export function preparaDadosChartPie(data) {
    let chartData = [['tipo', 'qtd']];
    for (const chave in data) {
        let tipo = data[chave];
        chartData.push([chave, tipo.percentual + 0]);
    }
    return chartData;
}

export function removerAcentos(palavra) {
    return palavra.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
