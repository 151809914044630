export const initialState = {
    filterInitialFarm: null,
    filterInitialHarvest: null
};

const filtroInicialReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_FILTER_INITIAL_FARM':
            return {
                ...state,
                filterInitialFarm: actions.payload
            };
        case 'SET_STATE_INITIAL_HARVEST':
            return {
                ...state,
                filterHarvest: actions.payload
            };
        default:
            return state;
    }
};

export default filtroInicialReducer;
