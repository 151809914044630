export const initialState = {
    date: null,
    stateDrawer: false,
    allData: null,
    allActivities: null,
    allDataOrigin: null
};

const agendaMacroReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_FILTER_DATE':
            return {
                ...state,
                date: actions.payload
            };
        case 'SET_STATE_DRAWER':
            return {
                ...state,
                stateDrawer: actions.payload
            };
        case 'SET_ALL_DATA_TABLE':
            return {
                ...state,
                allData: actions.payload
            };
        case 'SET_ALL_DATA_ORIGIN':
            return {
                ...state,
                allDataOrigin: actions.payload
            };
        case 'SET_ALL_ACTIVITIES':
            return {
                ...state,
                allActivities: actions.payload
            };
        default:
            return state;
    }
};

export default agendaMacroReducer;
