import React from 'react';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import Buttom from '../../components/Buttom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Container, Card } from './styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TableGridMacroSchedule from '../../components/Tables/TableGridMacroSchedule';
import BarHorizontal from '../../components/BarHorizontal';
import FormDrawer from '../../components/FormDrawer';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    list: {
        width: 450
    },
    fullList: {
        width: 'auto'
    }
});

function AgendaMacro() {
    const css = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const filterInitialFarm = searchParams.get('fazenda');
    const filterInitialHarvest = searchParams.get('safra');

    const farms = useSelector((state) => state.adubacao.farms);
    const tipos = useSelector((state) => state.adubacao.tipos);
    const servicos = useSelector((state) => state.adubacao.services);
    const status = useSelector((state) => state.adubacao.status);
    const activitys = useSelector((state) => state.adubacao.activitys);
    const groupServices = useSelector((state) => state.adubacao.groupServices);
    const locais = useSelector((state) => state.adubacao.locais);
    const allData = useSelector((state) => state.agendaMacro.allData);
    const allDataOrigin = useSelector((state) => state.agendaMacro.allDataOrigin);
    const allActivities = useSelector((state) => state.agendaMacro.allActivities);
    const loading = useSelector((state) => state.adubacao.loading);
    const dta = useSelector((state) => state.adubacao.dta);

    const [filterDate, setFilterDate] = useState([]);
    const [filterFarm, setFilterFarm] = useState([]);
    const [filterTipo, setFilterTipo] = useState([]);
    const [filterActivitys, setFilterActivitys] = useState([]);
    const [filterGroupService, setFilterGroupService] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [filterLocal, setFilterLocal] = useState([]);
    const [filterServices, setFilterServices] = useState([]);
    const [search, setSearch] = useState(false);

    const generateForm = [
        { data: farms, onChange: setFilterFarm, value: filterFarm, label: 'Fazenda', multiSelect: false, disabled: false },
        {
            data: locais,
            onChange: setFilterLocal,
            value: filterLocal,
            label: 'Local',
            multiSelect: true,
            labelHelper: 'Selecione uma fazenda',
            disabled: filterFarm.length <= 0 ? true : false
        },
        { data: dta, onChange: setFilterDate, value: filterDate, label: 'Mês', multiSelect: true, disabled: false },

        { data: status, onChange: setFilterStatus, value: filterStatus, label: 'Status', multiSelect: true, disabled: false },

        { data: activitys, onChange: setFilterActivitys, value: filterActivitys, label: 'Atividade', multiSelect: true, disabled: false },
        {
            data: groupServices,
            onChange: setFilterGroupService,
            value: filterGroupService,
            label: 'Grupo de serviço',
            multiSelect: true,
            disabled: false
        },
        { data: servicos, onChange: setFilterServices, value: filterServices, label: 'Serviço', multiSelect: true, disabled: false }
    ];

    function clearFilter() {
        setFilterFarm([]);
        setFilterDate([]);
        setFilterActivitys([]);
        setFilterGroupService([]);
        setFilterStatus([]);
        setFilterLocal([]);
        setFilterServices([]);
        setSearch(!search);
    }

    const toggleDrawerForm = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const drawerFormContent = () => (
        <div className={css.list} role="presentation" onClick={toggleDrawerForm(false)} onKeyDown={toggleDrawerForm(false)}>
            <Grid
                container
                spacing={0}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                style={{ padding: '25px' }}
            >
                <FormDrawer generateForm={generateForm} clearFilter={clearFilter} setSearch={setSearch} search={search} />
            </Grid>
        </div>
    );

    return (
        <Container container>
            <Grid container>
                <Grid item xs={2}>
                    <div>
                        <h1 style={{ color: '#015641' }}>Agenda Macro</h1>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                        <Buttom
                            label="Filtros"
                            onClick={toggleDrawerForm(true)}
                            marginRight={10}
                            icon={<FilterAltIcon />}
                            disabled={loading}
                        />
                    </div>
                </Grid>
                <Grid item xs={9} container justify="flex-end" alignItems="center">
                    <Link to={`/?fazenda=${filterInitialFarm}&safra=${filterInitialHarvest}`}>
                        <HomeIcon style={{ color: '#015641', fontSize: '48px', marginTop: '15px' }} />
                    </Link>
                </Grid>
                <Grid container xs={3}>
                    <Grid item xs={12} container direction="row">
                        <Card>
                            <header>Total Área</header>
                            <main>
                                <p>*Sem registro na db*</p>
                            </main>
                        </Card>
                        <Card>
                            <header>Qtd de Atividades</header>
                            <main>
                                <h2>{allActivities !== null ? allActivities.length : 0}</h2>
                            </main>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        {!loading && <BarHorizontal allData={allData} />}
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <div style={{ height: '88vh' }}>
                        <TableGridMacroSchedule
                            filterDate={filterDate}
                            filterLocal={filterLocal}
                            filterActivitys={filterActivitys}
                            filterGroupService={filterGroupService}
                            filterStatus={filterStatus}
                            filterFarm={filterFarm}
                            filterServices={filterServices}
                            search={search}
                            filterInitialFarm={filterInitialFarm}
                            filterInitialHarvest={filterInitialHarvest}
                        />
                    </div>
                </Grid>
            </Grid>
            <Drawer open={isDrawerOpen} onClose={toggleDrawerForm(false)} anchor="left">
                {drawerFormContent()}
            </Drawer>
        </Container>
    );
}

export default AgendaMacro;
