import React from 'react';
import { Grid } from '@material-ui/core';
import TelaB from '../../components/TelaB';
import Buttom from '../../components/Buttom';
import MultipleCheckbox from '../../components/MultipleCheckbox';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SearchIcon from '@mui/icons-material/Search';
export default function FormDrawer({ generateForm, clearFilter, setSearch, search }) {
    //console.log('gen', generateForm);
    return (
        <Grid item xs={12}>
            <h3 style={{ color: '#015641' }}>FILTROS</h3>
            {generateForm.map((value) => (
                <>
                    {value.multiSelect === false ? (
                        <div style={{ marginBottom: '20px' }}>
                            <TelaB data={value.data} label={value.label} onChange={value.onChange} value={value.value} />
                        </div>
                    ) : (
                        <div style={{ marginTop: '20px' }}>
                            <MultipleCheckbox
                                data={value.data}
                                label={value.label}
                                labelHelper={value.labelHelper !== undefined ? value.labelHelper : ''}
                                onChange={value.onChange}
                                value={value.value}
                                disabled={value.disabled}
                            />
                        </div>
                    )}
                </>
            ))}
            <div>
                <Buttom label="Buscar" onClick={() => setSearch(!search)} icon={<SearchIcon />} fullWidth />
                <Buttom label="Limpar filtros" onClick={clearFilter} icon={<FilterAltOffIcon />} fullWidth />
            </div>
        </Grid>
    );
}
