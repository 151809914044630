import * as React from 'react';
import { Box, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';

export default function BasicSelect({ data, label, value, onChange }) {
    return (
        <Box sx={{ minWidth: 120 }}>
            <TextField label={label} select value={value} onChange={(e) => onChange(e.target.value)} fullWidth>
                {data?.map((value, key) => (
                    <MenuItem key={key} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
}
