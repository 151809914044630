import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Buttom from '../../components/Buttom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Container, Card } from './styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TableGridMacroSchedule from '../../components/Tables/TableGridMacroSchedule';
import FormDrawer from '../../components/FormDrawer';
import { getMoMa } from '../../services/bi';
import { v4 as uuidv4 } from 'uuid';
import { createColumnsPerMonth, createFilter, createColumnsPerGroupService, createColumnsPerService } from './functions';
import { DataGrid } from '@mui/x-data-grid';
import Loading from '../../components/Loading';
import { months } from '../../utils/months';
import './styled.css';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    list: {
        width: 450
    },
    fullList: {
        width: 'auto'
    }
});

export default function MoMa() {
    var allDataMoMa = useSelector((state) => state.allData.allDataMoMa);

    // Verifica se o estado é null
    if (allDataMoMa === null) {
        // Tenta recuperar o estado do localStorage
        const serializedStateMoMa = localStorage.getItem('dataMoMa');
        if (serializedStateMoMa !== null) {
            // Converte o estado de volta para um objeto JavaScript
            allDataMoMa = JSON.parse(serializedStateMoMa);
        } else {
            // Se não houver estado no localStorage, redireciona para a home
            history.push('/home');
        }
    }
    const searchParams = new URLSearchParams(location.search);
    const filterInitialFarm = searchParams.get('fazenda');
    const filterInitialHarvest = searchParams.get('safra');

    const css = useStyles();
    const _ = require('lodash');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [allDataPerMonth, setAllDataPerMonth] = useState([]);
    const [allDataPerGroupService, setAllDataPerGroupService] = useState([]);
    const [allDataPerService, setAllDataPerService] = useState([]);
    const [allFilter, setAllFilter] = useState([]);
    const [filterDate, setFilterDate] = useState([]);
    const [filterFarm, setFilterFarm] = useState([]);
    const [filterActivitys, setFilterActivitys] = useState([]);
    const [filterGroupService, setFilterGroupService] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);
    const [filterLocal, setFilterLocal] = useState([]);
    const [filterServices, setFilterServices] = useState([]);
    const [search, setSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allDataOriginal, setAllDataOriginal] = useState([]);
    const generateForm = [
        {
            data: allFilter.allFarm,
            onChange: setFilterFarm,
            value: filterFarm,
            label: 'Fazenda',
            multiSelect: false,
            disabled: false
        },
        {
            data: allFilter.allLocal,
            onChange: setFilterLocal,
            value: filterLocal,
            label: 'Local',
            multiSelect: true,
            labelHelper: 'Selecione uma fazenda',
            disabled: filterFarm.length <= 0 ? true : false
        },
        {
            data: allFilter.allDta,
            onChange: setFilterDate,
            value: filterDate,
            label: 'Mês',
            multiSelect: true,
            disabled: false
        },

        {
            data: allFilter.allStatus,
            onChange: setFilterStatus,
            value: filterStatus,
            label: 'Status',
            multiSelect: true,
            disabled: false
        },

        {
            data: allFilter.allActivity,
            onChange: setFilterActivitys,
            value: filterActivitys,
            label: 'Atividade',
            multiSelect: true,
            disabled: false
        },
        {
            data: allFilter.allGroupService,
            onChange: setFilterGroupService,
            value: filterGroupService,
            label: 'Grupo de serviço',
            multiSelect: true,
            disabled: false
        },
        {
            data: allFilter.allServico,
            onChange: setFilterServices,
            value: filterServices,
            label: 'Serviço',
            multiSelect: true,
            disabled: false
        }
    ];

    function clearFilter() {
        setFilterFarm([]);
        setFilterDate([]);
        setFilterActivitys([]);
        setFilterGroupService([]);
        setFilterStatus([]);
        setFilterLocal([]);
        setFilterServices([]);
        setSearch(!search);
    }

    const toggleDrawerForm = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const drawerFormContent = () => (
        <div className={css.list} role="presentation" onClick={toggleDrawerForm(false)} onKeyDown={toggleDrawerForm(false)}>
            <Grid
                container
                spacing={0}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                style={{ padding: '25px' }}
            >
                <FormDrawer generateForm={generateForm} clearFilter={clearFilter} setSearch={setSearch} search={search} />
            </Grid>
        </div>
    );

    function warehouseValues(value) {
        if (value.data.length > 0) {
            const perService = value.data.sort((a, b) => {
                // ordenar por servico
                const compareServico = a.servico.localeCompare(b.servico);
                if (compareServico !== 0) {
                    return compareServico;
                }
                // se servico for igual, ordenar por local
                return a.local.localeCompare(b.local);
            });

            const perGroupService = value.data.sort((a, b) => a.grupo_servico.localeCompare(b.grupo_servico));
            const dataPerService = createColumnsPerService(perService);
            const dataPerGroupService = createColumnsPerGroupService(perGroupService);
            setAllDataPerGroupService(dataPerGroupService);
            setAllDataPerService(dataPerService);
        }
        const dataPerMonth = createColumnsPerMonth(value.data);

        setAllDataPerMonth(dataPerMonth);
        setAllDataOriginal(value.data);
        setAllFilter(createFilter(value.data));
    }

    function filterData() {
        const filteredData = allDataOriginal.filter((obj) => obj.fazenda === filterFarm);
        const uniqueLocais = filteredData.reduce((acc, obj) => {
            acc[obj.local] = true;
            return acc;
        }, {});
        const newFilteredLocais = Object.keys(uniqueLocais);

        setAllFilter((prevFilter) => ({ ...prevFilter, allLocal: newFilteredLocais }));
        const filteredRows = _.filter(allDataOriginal, (row) => {
            const propsToCheck = ['status', 'activitys', 'groupService', 'farm', 'local', 'service', 'dta'];
            const filters = {
                status: _.isEmpty(filterStatus) || _.includes(filterStatus, row.status),
                activitys: _.isEmpty(filterActivitys) || _.includes(filterActivitys, row.atividade),
                groupService: _.isEmpty(filterGroupService) || _.includes(filterGroupService, row.grupo_servico),
                farm: _.isEmpty(filterFarm) || _.includes(filterFarm, row.fazenda),
                local: _.isEmpty(filterLocal) || _.includes(filterLocal, row.local),
                service: _.isEmpty(filterServices) || _.includes(filterServices, row.servicoOrigin),
                dta: _.isEmpty(filterDate) || _.includes(filterDate, row.mes)
            };
            return _.every(_.pick(filters, propsToCheck));
        });
        setAllDataPerMonth(createColumnsPerMonth(filteredRows));
        setAllDataPerGroupService(createColumnsPerGroupService(filteredRows));
        const filteredRowsPerService = filteredRows;
        if (filteredRowsPerService.length > 0 && filteredRowsPerService[0].servico === null) {
            // Atribui o valor da propriedade "servicoOrigin"
            filteredRowsPerService[0].servico = filteredRowsPerService[0].servicoOrigin;
        }
        setAllDataPerService(createColumnsPerService(filteredRowsPerService));
    }

    function alterFilterFarm() {
        if (allDataOriginal !== null) {
            const filteredData = allDataOriginal?.filter((obj) => obj.fazenda === filterFarm);
            const uniqueLocais = filteredData?.reduce((acc, obj) => {
                acc[obj.local] = true;
                return acc;
            }, {});
            const newFilteredLocais = Object.keys(uniqueLocais);
            newFilteredLocais.sort((a, b) => a.localeCompare(b));

            setAllFilter((prevFilter) => ({ ...prevFilter, allLocal: newFilteredLocais }));
        }
    }

    useEffect(() => {
        if (
            filterDate.length > 0 ||
            filterStatus.length > 0 ||
            filterActivitys.length > 0 ||
            filterGroupService.length > 0 ||
            filterFarm.length > 0 ||
            filterServices.length > 0 ||
            (filterLocal.length > 0 && search)
        ) {
            return filterData(filterDate, filterStatus, filterActivitys, filterGroupService, filterFarm, filterLocal);
        }

        const dataPerMonth = createColumnsPerMonth(allDataOriginal);
        const dataPerGroupService = createColumnsPerGroupService(allDataOriginal);
        const dataPerService = createColumnsPerService(allDataOriginal);

        setAllFilter(createFilter(allDataOriginal));
        setAllDataPerMonth(dataPerMonth);
        setAllDataPerGroupService(dataPerGroupService);
        setAllDataPerService(dataPerService);
    }, [search]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            console.log('allData', allDataMoMa);
            if (allDataMoMa !== null) {
                allDataMoMa.data.forEach((obj) => {
                    obj.id = uuidv4();
                    obj.mes = months[obj.mes];
                    obj.servicoOrigin = obj.servico;
                });
                setIsLoading(false);
                warehouseValues(allDataMoMa);
            }
        }, 150);
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        alterFilterFarm();
    }, [filterFarm]);

    return (
        <Container container>
            <Grid container>
                <Grid item xs={5}>
                    <div>
                        <h1 style={{ color: '#015641' }}>Demanda de Mão de Obra e Máquinas</h1>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                        <Buttom
                            label="Filtros"
                            onClick={toggleDrawerForm(true)}
                            marginRight={10}
                            icon={<FilterAltIcon />}
                            disabled={isLoading}
                        />
                    </div>
                </Grid>
                <Grid item xs={3} container direction="row">
                    <Card>
                        <header>Total Área</header>
                        <main>
                            <h2>{allDataPerService?.totalArea?.toFixed(2)}</h2>
                        </main>
                    </Card>
                    <Card>
                        <header>Qtd de Atividades</header>
                        <main>
                            <h2>{allDataPerService?.rows?.length}</h2>
                        </main>
                    </Card>
                </Grid>
                <Grid item xs={3} container justify="flex-end" alignItems="center">
                    <Link to={`/?fazenda=${filterInitialFarm}&safra=${filterInitialHarvest}`}>
                        <HomeIcon style={{ color: '#015641', fontSize: '48px', marginTop: '15px', marginRight: '10px' }} />
                    </Link>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    {isLoading ? (
                        <div
                            style={{
                                height: '80vh',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            className="datagrid-wrapper"
                        >
                            <Loading>Carregando</Loading>
                        </div>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <div style={{ height: '38.3vh' }}>
                                    <DataGrid
                                        {...allDataPerMonth}
                                        disableColumnMenu
                                        style={{ backgroundColor: '#e4feec6c' }}
                                        showCellRightBorder={true}
                                    />
                                </div>
                                <div style={{ height: '39.1vh', marginTop: '1rem' }}>
                                    <DataGrid
                                        {...allDataPerGroupService}
                                        disableColumnMenu
                                        style={{ backgroundColor: '#e4feec6c' }}
                                        showCellRightBorder={true}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ height: '79vh' }}>
                                    <DataGrid
                                        {...allDataPerService}
                                        disableColumnMenu
                                        style={{ backgroundColor: '#e4feec6c' }}
                                        showCellRightBorder={true}
                                    />
                                </div>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <Drawer open={isDrawerOpen} onClose={toggleDrawerForm(false)} anchor="left">
                {drawerFormContent()}
            </Drawer>
        </Container>
    );
}
