import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import ProgressBar from '@ramonak/react-progress-bar';

export const Header = styled(Grid)`
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    border-bottom: 1px solid #000;
    margin-top: 1rem;

    > div {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    > h1 {
        color: #015641;
    }
`;

export const ProgressBarStyled = styled(ProgressBar)`
    width: 20%;
`;

export const Main = styled.div`
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;

    > p {
        font-size: 15px;
        color: #015641;
    }

    > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const Footer = styled.div`
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    > p {
        font-size: 15px;
        color: #015641;
    }

    > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const ButtonStyled = styled(Button)`
    background-color: #015641 !important;
    color: #ffff !important;
    margin: 0.5rem !important;
    width: 20%;
    height: 130px;
    box-shadow: 8px 4px 8px rgba(0, 0, 0, 0.25);

    &:hover {
        background-color: #018f6e !important;
    }

    &:disabled {
        background-color: gray !important;
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

export const LoadingButtonStyled = styled(LoadingButton)`
    background-color: #015641 !important;
    color: #ffff !important;

    &:hover {
        background-color: #018f6e !important;
    }

    &:disabled {
        background-color: gray !important;
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

export const LinkStyled = styled(Link)`
    text-decoration: none;
    color: #ffff !important;
`;
