export const initialState = {
    allData: null,
    loading: false,
    stateDrawer: false
};

const caracteristicaReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_ALL_DATA':
            return {
                ...state,
                allData: actions.payload
            };
        case 'SET_STATE_DRAWER':
            return {
                ...state,
                stateDrawer: actions.payload
            };
        default:
            return state;
    }
};

export default caracteristicaReducer;
