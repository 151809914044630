import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
    padding: 20px 20px 0px 0px;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-itens: right;
    background-color: #f4f4f4;
    height: 80vh;
    padding: 1rem;
`;
