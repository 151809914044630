import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';

export default function TableGridFarm({ novoValor, total, filterActivitys }) {
    const columns = [
        { field: 'id', hide: true },
        { field: 'fazenda', headerName: 'Fazenda', width: 100, sortable: false },
        { field: 'local', headerName: 'Local ', width: 100, sortable: false },
        { field: 'area', headerName: 'Área', width: 100, sortable: false },
        { field: 'atividade', headerName: 'Atividade', width: 100, sortable: false }
    ];

    const [dataFilter, setDataFilter] = useState({ rows: [], columns: columns });

    function filterByActivitys(classValue) {
        const filteredRows = novoValor.filter((row) => {
            let matchClass = true;
            if (classValue) {
                matchClass = row.atividade === classValue;
            }
            return matchClass;
        });
        const filteredColumns = columns.filter(
            (column) =>
                column.field === 'fazenda' ||
                column.field === 'local' ||
                column.field === 'area' ||
                column.field === 'atividade' ||
                filteredRows.some((row) => row[column.field] !== undefined)
        );

        const filteredData = filteredRows.map((row) => {
            const newRow = {};
            filteredColumns.forEach((column) => {
                newRow[column.field] = row[column.field];
            });
            return newRow;
        });
        return { rows: filteredData, columns: filteredColumns };
    }

    useEffect(() => {
        if (filterActivitys) {
            const filteredData = filterByActivitys(filterActivitys);
            setDataFilter(filteredData);
        } else {
            setDataFilter({ rows: novoValor, columns: columns });
        }
    }, [filterActivitys, novoValor]);

    console.log('data filter final countaw', dataFilter);
    return (
        <div style={{ height: '90%', width: '100%' }} className="datagrid-wrapper">
            <DataGrid
                //checkboxSelection
                // onSelectionModelChange={handleSelectionModelChange}
                {...dataFilter}
                disableColumnMenu
                style={{ backgroundColor: '#e4feec6c' }}
            />
            <div style={{ fontFamily: 'sans-serif', fontWeight: 'bold', color: '#015641', marginTop: '2rem' }}>Total HA: {total}</div>
        </div>
    );
}
