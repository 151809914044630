import * as React from 'react';
import Button from '@mui/material/Button';

export default function Buttom({ label, onClick, marginRight, icon, fullWidth, disabled }) {
    return (
        <Button
            disabled={disabled}
            endIcon={icon}
            fullWidth={fullWidth}
            variant="contained"
            onClick={onClick}
            style={{ backgroundColor: '#015641', marginTop: 10, marginRight: marginRight }}
        >
            {label}
        </Button>
    );
}
