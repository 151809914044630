import { DataGrid } from '@mui/x-data-grid';
import './styles.css';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFertilizing } from '../../../services/bi';
import Loading from '../../Loading';

export default function TableGridFertilizing({
    filterClass,
    filterInput,
    filterUnit,
    filterDate,
    filterStatus,
    filterActivitys,
    filterGroupService,
    filterFarm,
    filterLocal,
    search
}) {
    // Lê o estado atual do Redux
    var dataAdubacao = useSelector((state) => state.allData.allDataAdubacao);

    // Verifica se o estado é null
    if (dataAdubacao === null) {
        // Tenta recuperar o estado do localStorage
        const serializedStateAdubacao = localStorage.getItem('dataAdubacao');
        if (serializedStateAdubacao !== null) {
            // Converte o estado de volta para um objeto JavaScript
            dataAdubacao = JSON.parse(serializedStateAdubacao);
        } else {
            // Se não houver estado no localStorage, redireciona para a home
            history.push('/home');
        }
    }

    const [allData, setAllData] = useState(null);
    console.log('alldata', allData);
    const [allDataOriginal, setAllDataOriginal] = useState(null);
    const [dataFilter, setDataFilter] = useState(allData);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const _ = require('lodash');
    dispatch({ type: 'SET_FILTER_LOADING', payload: isLoading });

    const Decimal = require('decimal.js');

    function createFilter(allClass, allInput, allFarm, allActivity, allGroupService, allStatus, allLocal, allDate) {
        dispatch({ type: 'SET_FILTER_CLASS', payload: allClass });
        dispatch({ type: 'SET_FILTER_INPUT', payload: allInput });
        dispatch({ type: 'SET_FILTER_DATE', payload: allDate });
        dispatch({ type: 'SET_FILTER_FARM', payload: allFarm });
        dispatch({ type: 'SET_FILTER_ACTIVITY', payload: allActivity });
        dispatch({ type: 'SET_FILTER_STATUS', payload: allStatus });
        dispatch({ type: 'SET_FILTER_GROUP_SERVICE', payload: allGroupService });
        dispatch({ type: 'SET_FILTER_LOCAL', payload: allLocal });
    }

    function createRowsGrouping(data) {
        const grouped = {};

        data.rows.forEach((row) => {
            const input = row.input;
            const un = row.un;
            const classValue = row.class;
            const status = row.status;
            const fazenda = row.fazenda;
            const atividade = row.atividade;
            const grupo_servico = row.grupo_servico;
            const id = row.id;
            const key = `${classValue}-${input}-${un}`;

            if (!grouped[key]) {
                grouped[key] = {
                    class: classValue,
                    input,
                    un,
                    id,
                    status,
                    fazenda,
                    atividade,
                    grupo_servico,
                    'Total Geral': 0,
                    local: [],
                    totals: {}
                };
            }

            grouped[key].local.push(row.local);

            for (const [prop, value] of Object.entries(row)) {
                if (prop.match(/^\w{3}\.\d{2}$/)) {
                    const [month, year] = prop.split('.');
                    const total = parseFloat(value) || 0;
                    const monthKey = `${month}.${year}`;

                    grouped[key].totals[monthKey] = (grouped[key].totals[monthKey] || 0) + total;
                } else if (prop.match(/^Total/)) {
                    const year = prop.split(' ')[1];
                    const yearTotal = parseFloat(value) || 0;

                    grouped[key].totals[`Total ${year}`] = (grouped[key].totals[`Total ${year}`] || 0) + yearTotal;

                    if (prop === 'Total') {
                        grouped[key]['Total Geral'] += yearTotal;
                    } else {
                        const [_, year] = prop.split(' ');
                        grouped[key]['Total Geral'] += yearTotal + parseFloat(row[`Total ${year}`]) || 0;
                    }
                }
            }
        });

        for (const [key, group] of Object.entries(grouped)) {
            for (const [month, total] of Object.entries(group.totals)) {
                group[month] = total.toFixed(2);
            }

            const totalYears = Object.entries(group.totals).filter(([key]) => key.startsWith('Total'));
            for (const [yearKey, yearTotal] of totalYears) {
                group[yearKey] = yearTotal.toFixed(2);
            }

            delete group.totals;
        }

        const rows = Object.values(grouped);
        return rows;
    }

    const createColumns = (data) => {
        const months = new Set();
        const years = new Set();
        const rows = [];

        data.forEach((row) => {
            Object.keys(row).forEach((key) => {
                if (key.match(/^[a-z]{3}\.[0-9]{2}$/i)) {
                    row[key] = new Decimal(row[key] || 0);
                    rows.push(row);
                    const [month, year] = key.split('.');
                    months.add(`${month}.${year}`);
                    years.add(year);
                }
            });
        });

        const sortedMonths = [...months].sort((a, b) => {
            const [aMonth, aYear] = a.split('.');
            const [bMonth, bYear] = b.split('.');
            if (aYear === bYear) {
                const monthOrder = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
                return monthOrder.indexOf(aMonth) - monthOrder.indexOf(bMonth);
            }
            return parseInt(aYear) - parseInt(bYear);
        });

        const columns = [
            { field: 'id', hide: true },
            { field: 'class', headerName: 'Classe', width: 180, sortable: false, hide: false },
            { field: 'input', headerName: 'Insumos', width: 180, sortable: false, hide: false },
            { field: 'fazenda', headerName: 'Fazenda', width: 180, sortable: false, hide: true },
            { field: 'local', headerName: 'Local', width: 180, sortable: false, hide: true },
            { field: 'grupo_servico', headerName: 'Grupo de serviço', width: 180, sortable: false, hide: true },
            { field: 'un', headerName: 'Un', width: 110, sortable: false, hide: false },
            { field: 'status', headerName: 'Status', width: 110, sortable: false, hide: true },

            ...sortedMonths.map((m) => ({
                field: m,
                headerName: m,
                width: 120,
                type: 'number',
                sortable: true,
                valueFormatter: (params) => {
                    const value = params.value || 0;
                    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
                }
            }))
        ];

        const totalColumns = [];
        const currentDate = new Date();

        const yearsValues = [...years.values()];
        console.log('yearsValues', yearsValues);
        for (let year = parseInt(`20${yearsValues[0]}`); year <= parseInt(`20${yearsValues[1]}`); year++) {
            const totalField = `Total ${year}`;

            const yearColumn = {
                field: totalField,
                headerName: totalField,
                width: 150,
                sortable: false,
                align: 'right',
                headerAlign: 'right',
                valueFormatter: (params) => {
                    const value = params.value || 0;
                    return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
                }
            };
            columns.push(yearColumn);
            totalColumns.push(yearColumn);
        }

        for (let i = 0; i < data.length; i++) {
            let total = new Decimal(0);
            for (let year = parseInt(`20${yearsValues[0]}`); year <= parseInt(`20${yearsValues[1]}`); year++) {
                const yearTotal = totalColumns.find((column) => column.field === `Total ${year}`);
                const yearColumns = sortedMonths.filter((mes) => mes.endsWith(year.toString().substr(-2)));
                const yearTotalValue = yearColumns.reduce((acc, month) => {
                    const monthValue = data[i][month] || 0;
                    return acc.add(monthValue);
                }, new Decimal(0));
                data[i][yearTotal.field] = yearTotalValue;
                total = total.add(yearTotalValue); // Adiciona o valor do ano atual ao total
            }
            data[i]['Total Geral'] = total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Converte e formata o valor total
        }

        columns.push({
            field: 'Total Geral',
            headerName: 'Total Geral',
            width: 150,
            sortable: false,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) => {
                const value = params.value || 0;
                return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
            }
        });

        let tempObj = { class: '' };
        rows.sort((a, b) => a.class.localeCompare(b.class) || a.id - b.id);

        const allClass = [];
        const allInputObj = {};
        const allFarmObj = {};
        const allActivityObj = {};
        const allStatusObj = {};
        const allGroupServiceObj = {};
        const allLocalObj = {};

        for (let i = 0; i < rows.length; i++) {
            rows[i].class = rows[i].class;
            if (tempObj.class === rows[i].class) {
                rows[i].class = rows[i].class;
            } else {
                tempObj = rows[i];
                tempObj.class = tempObj.class;
                allClass.push(rows[i].class);
            }

            const input = rows[i].input;
            if (!allInputObj[input]) {
                allInputObj[input] = true;
            }

            const farm = rows[i].fazenda;
            if (!allFarmObj[farm]) {
                allFarmObj[farm] = true;
            }

            const atividade = rows[i].atividade;
            if (!allActivityObj[atividade]) {
                allActivityObj[atividade] = true;
            }

            const grupoServico = rows[i].grupo_servico;
            if (!allGroupServiceObj[grupoServico]) {
                allGroupServiceObj[grupoServico] = true;
            }

            const status = rows[i].status;
            if (!allStatusObj[status]) {
                allStatusObj[status] = true;
            }

            const local = rows[i].local;
            if (!allLocalObj[local]) {
                allLocalObj[local] = true;
            }
        }
        const allInput = Object.keys(allInputObj);
        const allFarm = Object.keys(allFarmObj);
        const allActivity = Object.keys(allActivityObj);
        const allGroupService = Object.keys(allGroupServiceObj);
        const allStatus = Object.keys(allStatusObj);
        const allLocal = Object.keys(allLocalObj);

        allInput.sort((a, b) => a.localeCompare(b));
        allFarm.sort((a, b) => a.localeCompare(b));
        allActivity.sort((a, b) => a.localeCompare(b));
        allGroupService.sort((a, b) => a.localeCompare(b));
        allStatus.sort((a, b) => a.localeCompare(b));
        allLocal.sort((a, b) => a.localeCompare(b));

        createFilter(allClass, allInput, allFarm, allActivity, allGroupService, allStatus, allLocal, sortedMonths);

        const rowsGruped = ([] = createRowsGrouping({ rows: rows }));
        rowsGruped.sort((a, b) => {
            if (a.class < b.class) {
                return -1;
            }
            if (a.class > b.class) {
                return 1;
            }
            // se os valores de grupo_servico forem iguais, ordene por servico
            if (a.input < b.input) {
                return -1;
            }
            if (a.input > b.input) {
                return 1;
            }
            return 0; // retorna 0 se os objetos forem iguais
        });
        return { rows: rowsGruped, columns };
    };

    function filterByClass(
        classValues,
        inputValue,
        filterUnit,
        filterDate = '',
        filterStatus,
        filterActivitys,
        filterGroupService,
        filterFarm,
        filterLocal
    ) {
        const filteredData = allDataOriginal.filter((obj) => obj.fazenda === filterFarm);
        const uniqueLocais = filteredData.reduce((acc, obj) => {
            acc[obj.local] = true;
            return acc;
        }, {});
        const newFilteredLocais = Object.keys(uniqueLocais);

        newFilteredLocais.sort((a, b) => a.localeCompare(b));
        dispatch({ type: 'SET_FILTER_LOCAL', payload: newFilteredLocais });

        const filteredRows = _.filter(allDataOriginal, (row) => {
            const propsToCheck = ['classValue', 'input', 'un', 'status', 'activitys', 'groupService', 'farm', 'local'];
            const filters = {
                classValue: _.isEmpty(classValues) || _.includes(classValues, row.class),
                input: _.isEmpty(inputValue) || _.includes(row.input, inputValue),
                un: _.isEmpty(filterUnit) || _.includes(row.un.toUpperCase(), filterUnit),
                status: _.isEmpty(filterStatus) || _.includes(filterStatus, row.status),
                activitys: _.isEmpty(filterActivitys) || _.includes(filterActivitys, row.atividade),
                groupService: _.isEmpty(filterGroupService) || _.includes(filterGroupService, row.grupo_servico),
                farm: _.isEmpty(filterFarm) || _.includes(filterFarm, row.fazenda),
                local: _.isEmpty(filterLocal) || _.includes(filterLocal, row.local)
            };

            return _.every(_.pick(filters, propsToCheck));
        });

        if (filterDate.length <= 0) {
            const visibleColumns = allData.columns.filter(
                (column) =>
                    column.field === 'id' ||
                    column.field === 'class' ||
                    column.field === 'input' ||
                    column.field === 'un' ||
                    column.field === 'status' ||
                    column.field === 'atividade' ||
                    column.field === 'fazenda' ||
                    column.field === 'local' ||
                    _.some(filteredRows, (row) => _.has(row, column.field))
            );

            const filteredData = filteredRows.map((row) => _.pick(row, _.map(visibleColumns, 'field')));
            const rowsColumns = { rows: filteredData, columns: visibleColumns };
            const rowsGruping = createRowsGrouping(rowsColumns);
            rowsGruping.sort((a, b) => {
                if (a.class < b.class) {
                    return -1;
                }
                if (a.class > b.class) {
                    return 1;
                }
                // se os valores de grupo_servico forem iguais, ordene por servico
                if (a.input < b.input) {
                    return -1;
                }
                if (a.input > b.input) {
                    return 1;
                }
                return 0; // retorna 0 se os objetos forem iguais
            });

            setDataFilter({ rows: rowsGruping, columns: visibleColumns });
        } else if (filterDate.length === 1) {
            // colunas a serem exibidas se houver filtro por uma única data
            const visibleColumns = allData.columns.filter(
                (column) =>
                    column.field === 'id' ||
                    column.field === 'class' ||
                    column.field === 'input' ||
                    column.field === 'un' ||
                    column.field === 'status' ||
                    column.field === 'atividade' ||
                    column.field === 'fazenda' ||
                    column.field === 'local' ||
                    column.field === filterDate[0]
            );

            const filteredData = filteredRows.map((row) => _.pick(row, _.map(visibleColumns, 'field')));

            const rowsColumns = { rows: filteredData, columns: visibleColumns };
            const rowsGruping = createRowsGrouping(rowsColumns);
            rowsGruping.sort((a, b) => {
                if (a.class < b.class) {
                    return -1;
                }
                if (a.class > b.class) {
                    return 1;
                }
                // se os valores de grupo_servico forem iguais, ordene por servico
                if (a.input < b.input) {
                    return -1;
                }
                if (a.input > b.input) {
                    return 1;
                }
                return 0; // retorna 0 se os objetos forem iguais
            });

            setDataFilter({ rows: rowsGruping, columns: visibleColumns });
        } else {
            const visibleColumns = allData.columns.filter(
                (column) =>
                    column.field === 'id' ||
                    column.field === 'class' ||
                    column.field === 'input' ||
                    column.field === 'un' ||
                    column.field === 'status' ||
                    column.field === 'atividade' ||
                    column.field === 'fazenda' ||
                    column.field === 'local' ||
                    _.includes(filterDate, column.field)
            );
            const filteredData = filteredRows.map((row) => _.pick(row, _.map(visibleColumns, 'field')));
            const rowsColumns = { rows: filteredData, columns: visibleColumns };
            const rowsGruping = createRowsGrouping(rowsColumns);
            rowsGruping.sort((a, b) => {
                if (a.class < b.class) {
                    return -1;
                }
                if (a.class > b.class) {
                    return 1;
                }
                // se os valores de grupo_servico forem iguais, ordene por servico
                if (a.input < b.input) {
                    return -1;
                }
                if (a.input > b.input) {
                    return 1;
                }
                return 0; // retorna 0 se os objetos forem iguais
            });

            setDataFilter({ rows: rowsGruping, columns: visibleColumns });
        }
    }
    function alterFilterFarm() {
        if (allDataOriginal !== null) {
            const filteredData = allDataOriginal?.filter((obj) => obj.fazenda === filterFarm);
            const uniqueLocais = filteredData?.reduce((acc, obj) => {
                acc[obj.local] = true;
                return acc;
            }, {});
            const newFilteredLocais = Object.keys(uniqueLocais);
            newFilteredLocais.sort((a, b) => a.localeCompare(b));

            dispatch({ type: 'SET_FILTER_LOCAL', payload: newFilteredLocais });
        }
    }

    useEffect(() => {
        if (
            filterClass.length > 0 ||
            filterInput.length > 0 ||
            filterUnit.length > 0 ||
            filterDate.length > 0 ||
            filterStatus.length > 0 ||
            filterActivitys.length > 0 ||
            filterGroupService.length > 0 ||
            filterFarm.length > 0 ||
            (filterLocal.length > 0 && search)
        ) {
            return filterByClass(
                filterClass,
                filterInput,
                filterUnit,
                filterDate,
                filterStatus,
                filterActivitys,
                filterGroupService,
                filterFarm,
                filterLocal
            );
        }
        setDataFilter(allData);
    }, [search]);

    useEffect(() => {
        if (dataAdubacao !== null) {
            dataAdubacao.data.forEach((obj) => {
                obj.id = uuidv4();
            });
            const data = createColumns(dataAdubacao.data);
            setAllData(data);
            setDataFilter(data);
            setAllDataOriginal(dataAdubacao.data);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        alterFilterFarm();
    }, [filterFarm]);

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            className="datagrid-wrapper"
        >
            {isLoading && dataAdubacao == null ? (
                <Loading>Carregando</Loading>
            ) : (
                <>{dataFilter !== null && <DataGrid {...dataFilter} disableColumnMenu style={{ backgroundColor: '#e4feec6c' }} />}</>
            )}
        </div>
    );
}
