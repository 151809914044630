import { app } from './Api';

const getCaracteristica = async (fazenda, safra) => {
    return app.get(`caracteristica?fazenda=${fazenda}&safra=${safra}`);
};
const getFertilizing = async (fazenda, safra) => {
    return app.get(`manejo?fazenda=${fazenda}&safra=${safra}`);
};
const getAgendaMacro = async (fazenda, safra) => {
    return app.get(`agenda_macro?fazenda=${fazenda}&safra=${safra}`);
};

const getMoPreviewRealized = async (fazenda, safra) => {
    return app.get(`previsto_realizado?fazenda=${fazenda}&safra=${safra}`);
};

const getMoMa = async (fazenda, safra) => {
    return app.get(`demanda_mo_ma?fazenda=${fazenda}&safra=${safra}`);
};

const updateCache = async (fazenda, safra) => {
    return app.get(`grupo_safra?fazenda=${fazenda}&safra=${safra}`);
};

const getHomeInfo = async (fazenda, safra) => {
    return app.get(`tc_cache?fazenda=${fazenda}&safra=${safra}`);
};

export { getFertilizing, getAgendaMacro, getMoMa, getMoPreviewRealized, updateCache, getHomeInfo, getCaracteristica };
