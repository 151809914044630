import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Caracteristica from '../pages/Caracteristica';
import AgendaMacro from '../pages/AgendaMacro';
import Adubacao from '../pages/Adubacao';
import MoMa from '../pages/MoMa';
import Home from '../pages/Home';
import MoPrevistaRealizada from '../pages/MoPrevistaRealizada';

function Routers() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const fazenda = searchParams.get('fazenda') !== null ? searchParams.get('fazenda') : '41559565-65a8-4170-aba2-ce724a2089be';
    const safra = searchParams.get('safra') !== null ? searchParams.get('safra') : 'b8896e7a-718a-4ff5-aa12-ed3dba3154a6';

    return (
        <Routes>
            <Route path="/" element={<Home fazenda={fazenda} safra={safra} />} />
            <Route path="/adubacao" element={<Adubacao />} />
            <Route path="/agenda-macro" element={<AgendaMacro />} />
            <Route path="/mo-ma" element={<MoMa />} />
            <Route path="/caracteristica" element={<Caracteristica />} />
            <Route path="/mo-prevista-realizada" element={<MoPrevistaRealizada />} />
        </Routes>
    );
}

export default Routers;
