export const initialState = {
    allDataAdubacao: null,
    allDataAgendaMacro: null,
    allDataMoMa: null,
    allDataMoPrevistaRealizada: null
};

const allDataReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_ALLDATA_ADUBACAO':
            const serializedStateAdubacao = JSON.stringify(actions.payload);
            localStorage.setItem('dataAdubacao', serializedStateAdubacao);

            return {
                ...state,
                allDataAdubacao: actions.payload
            };
        case 'SET_ALLDATA_AGENDA_MACRO':
            const serializedStateAgendaMacro = JSON.stringify(actions.payload);
            localStorage.setItem('dataAgendaMacro', serializedStateAgendaMacro);

            return {
                ...state,
                allDataAgendaMacro: actions.payload
            };
        case 'SET_ALLDATA_CARACTERISTICA':
            const serializedStateCaracteristica = JSON.stringify(actions.payload);
            localStorage.setItem('dataCaracteristica', serializedStateCaracteristica);

            return {
                ...state,
                allDataMoMa: actions.payload
            };
        case 'SET_ALLDATA_MO_MA':
            const serializedStateMoMa = JSON.stringify(actions.payload);
            localStorage.setItem('dataMoMa', serializedStateMoMa);

            return {
                ...state,
                allDataMoMa: actions.payload
            };
        case 'SET_ALLDATA_MO_PREVISTA_REALIZADA':
            const serializedStateMoPrevistaRealizada = JSON.stringify(actions.payload);
            localStorage.setItem('dataMoPrevistaRealizada', serializedStateMoPrevistaRealizada);

            return {
                ...state,
                allDataMoPrevistaRealizada: actions.payload
            };
        default:
            return state;
    }
};

export default allDataReducer;
